<template>
  <el-autocomplete
      class="autocomplete-box"
      :size="size"
      :value="value"
      @input="$emit('change', $event)"
      :fetch-suggestions="fetchSearch"
      :placeholder="placeholder"
      :trigger-on-focus="focus"
      :value-key="valueKey"
      @select="handleSelect"
    ></el-autocomplete>
</template>

<script>
import { debounce } from 'lodash'
export default {
  name: 'a-auto-complete',
  components: {},
  // 自定义v-model，用于数据修改操作
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    size: {
      default: ''
    },
    placeholder: {
      default: ''
    },
    // 输入框获取交点时获取数据
    focus: {
      type: Boolean
    },
    // 输入框接收的内容
    value: {
      default: ''
    },
    // 下拉框中展示的键名，与cb中返回的数据中的属性值对应
    valueKey: {
      default: 'value'
    },
    // 下拉框数据从父组件获取
    asyncState: {
      type: Boolean
    },
    // 请求接口参数
    searchQuery: {
      default: () => ({})
    }
  },
  data() {
    return {
      // 默认参数
      defaultQuery: {
        page: 0,
        pageSize: 20,
      }
    };
  },
  watch: {},
  computed: {
    params() {
      const { defaultQuery, searchQuery } = this
      return Object.assign({...defaultQuery}, searchQuery)
    }
  },
  methods: {
    /**
    * 输入建议回调函数
    * @param {输入的内容} queryString
    * @param {回调函数} cb
    */
    fetchSearch: debounce(function(value, cb) {
      if (this.asyncState) {
        this.$emit('fetchSearch', value, cb)
      } else {
        this.getSearchData(value, cb)
      }
    }, 100),
    /**
    * 获取接口数据
    * @param {搜索关键字} query
    * @param {回调} cb
    */
    async getSearchData(query, cb) {
      query = query.replace(/\s/g, '');
      try {
        let { value } = await this.$axios.get('/acb/2.0/systems/loginUser/getParkName', {
          data: Object.assign({ parkName: query }, this.params)
        })
        cb(value.list);
      } catch (error) {
        console.log('getSearchData error', error)
      }
    },
    /**
    * 点击下拉框获取数据
    * @param {点击的下拉框数据} item
    */
    handleSelect(item) {
      console.log(this.valueKey, '--handleSelect--', item[this.valueKey])
      this.$emit('change', item[this.valueKey])
      this.$emit('select', item)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="stylus" scoped>

</style>
